import React from "react";
import refineOptions from '../assets/config/summaryRefineOptions';
import LoadingSpinner from "./LoadingSpinner";
import DropdownList from './DropdownList';

export default function SummaryRefineBox({refineOption, setRefineOption, loading, handleSubmit}) {

    return (
        <div className='container mx-auto p-4 border rounded-md shadow-md'>
            <DropdownList title="Refine Options" key_values={refineOptions} state={refineOption} stateSetter={setRefineOption}/>
            <div className="grid grid-cols-[80%_20%] gap-4">
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    type="submit"
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
                >
                    Refine
                </button>
                <div>
                    {loading ? <LoadingSpinner /> : ""}
                </div>
            </div>

        </div>
    );
}