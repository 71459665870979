import React, { useState, useEffect } from 'react';

export default function ComponentSwap({component1, component2, duration, switchTrigger}) {

    const [unloadComponent, setUnloadComponent] = useState(false);
    const [transitionOutStart, setTransitionOutStart] = useState(false);
    const [transitionInStart, setTransitionInStart] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false)
        }
        else {
            setTransitionOutStart(!transitionOutStart)
            setTimeout(() => {
                setUnloadComponent(!unloadComponent)
                setTransitionInStart(!transitionInStart)
            }, duration)
        }
    }, [switchTrigger]) // <-- here put the parameter to listen


    return (
        <div>
            {unloadComponent ? 
            <div className={`transition-opacity duration-${duration} ${transitionOutStart ? 'opacity-100' : 'opacity-0'}`}>
                {component1}
            </div>
            :
            <div className={`transition-opacity duration-${duration} ${transitionInStart ? 'opacity-0' : 'opacity-100'}`}>
                {component2}
            </div>
            }
        </div>
    );
}
