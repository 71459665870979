import React from 'react';
import { Outlet, Navigate } from "react-router-dom";

import PrivateNavbar from '../components/PrivateNavbar';
import Sidebar from '../components/Sidebar'

export default function PrivateLayout ({user}) {

    return (
        <div class="min-h-screen w-full relative">     
            <PrivateNavbar />
            <Sidebar />
            <div class="p-4 py-20 sm:ml-64">
                <main><Outlet /></main>
            </div>
        </div> 
    )
}