import React from "react";
import { Tooltip } from 'flowbite-react';

export default function DropdownList({title, key_values, state, stateSetter, tooltipText}) {

    function createSelectItems() {
        let items = [];   
        Object.keys(key_values).forEach((key) => {
            let value = key_values[key]
            items.push(<option key={key} value={value}>{key}</option>)
        })
  
        return items;
    }

    function onDropdownSelected(e) {
        stateSetter(e.target.value)
        //here you will see the current selected value of the select input
    }

    return (
        <div className="mb-4 mt-4">
            <Tooltip
                content={tooltipText}
                placement="right"
            >
                <label htmlFor={title} className="block mb-2 mt-2 font-bold">
                    {title}
                </label>
            </Tooltip>
            <select
                id={title}
                name={title}
                className="w-full p-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={onDropdownSelected}
                value={state}
            >
                {createSelectItems()}
            </select>
        </div>
    );
}