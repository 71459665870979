import React from 'react';
import RadioButton from './RadioButton';
import { Tooltip } from 'flowbite-react';

export default function RadioButtonBar({title, key_values, state, stateSetter, tooltipText=""}) {

    function createRadioButtons() {
        let items = [];     
        Object.keys(key_values).forEach((key) => {
            let value = key_values[key]
            items.push(<RadioButton title={title} text={key} value={value} state={state} stateSetter={stateSetter} />)
        })   
        return items;
    }

    return (
        <div>
            <Tooltip
                content={tooltipText}
                placement="right"
            >
                <label htmlFor={title} className="block mb-2 mt-2 font-bold">
                    {title}
                </label>
            </Tooltip>

            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                {createRadioButtons()}
            </ul>
        </div>
    );
}