import React from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function PromptInputBox({inputText, setInputText, loading, handleSubmit}) {

  const handleInputTextChange  = (e) => {
    setInputText(e.target.value);
  };

  return (
    <div className="container mx-auto mb-6 p-4 border rounded-md shadow-md">
      <div className="mb-4">
        <div>
          <label htmlFor="inputText" className="block mb-2 font-bold">
            Input Text
          </label>
          <textarea
            id="inputText"
            name="inputText"
            className={`transition-transform w-full p-2 border border-gray-400 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 ease-in-out duration-1000`}
            rows="6"
            value={inputText}
            onChange={handleInputTextChange}
          ></textarea>
        </div>

        <div className="grid grid-cols-[20%_10%_70%] gap-4 mb-4">
          <div>
            <button
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
            >
              Summarize
            </button>
          </div>
          <div>
            {loading ? <LoadingSpinner /> : ""}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}