import React from 'react';

export default function NoPage() {
    return (

    <div class="min-h-screen w-full relative">
        
        <div className="container mx-auto p-4">
            Nothing Here
        </div>
    </div>
    );  
}