import React from "react";

export default function GenericTextBox({name, labelText, boxText, setBoxText}) {

    const handleBoxTextChange  = (e) => {
        setBoxText(e.target.value);
    };

    return (
    <div className="mb-4">
        <label htmlFor={name} className="block mb-2 font-bold">
            {labelText}
        </label>
        <textarea
            id={name}
            name={name}
            className={`transition-transform w-full p-2 border border-gray-400 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 ease-in-out duration-1000`}
            rows="6"
            value={boxText}
            onChange={handleBoxTextChange}
        ></textarea>
    </div>

    );
}