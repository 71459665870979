import React, { useState } from 'react';
import axios from 'axios';

import FormattingOptionsBox from '../components/FormattingOptionsBox';
import GenericTextBox from '../components/GenericTextBox';
import PromptInputBox from '../components/PromptInputBoxWButton';
import PromptResultBox from '../components/PromptResultBox';
import SummaryRefineBox from '../components/SummaryRefineBox';
import ComponentSwap from '../components/ComponentSwap';

export default function TextSummarizer() {
    // Frontend variables
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const promptHandlerUrl = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_API;

    // Settings for formatting and prompt tweaks
    const [headings, setHeadings] = useState("");
    const [bullets, setBullets] = useState(true);
    const [pointLength, setPointLength] = useState("normal");
    const [attentionToDetail, setAttentionToDetail] = useState("normal");
    const [persona, setPersona] = useState("pharma_normal");

    // Settings for model
    const [preferredModelName, setPreferredModelName] = useState("");

    // Settings for prompt
    const toolName = "Text Summarizer"
    const [inputText, setInputText] = useState("");
    const [example, setExample] = useState("");
    const [description, setDescription] = useState("");
    const [splitMode, setSplitMode] = useState("map");
    const [summaryText, setSummaryText] = useState("");
    const [refineOption, setRefineOption] = useState("");

    let postData = {
        headings: headings,
        example: example,
        bullets: bullets,
        pointLength: pointLength,
        attentionToDetail: attentionToDetail,
        persona: persona,
        preferredModelName: preferredModelName,
        inputText: inputText,
        description: description,
        splitMode: splitMode,
        summaryText: summaryText,
        refineOption: refineOption,
        toolName: toolName
    }

    const handleSummarizeSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(promptHandlerUrl, postData)
        .then(response => {
            setSummaryText(response.data.summary);
            console.log("Success");
            console.log(response);
            setLoading(false);
            setShowResult(true); // Show the result once the data is fetched
        })
        .catch(error => {
            console.log(error);
        });
    };

    const handleRefineSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(promptHandlerUrl, postData)
    .then(response => {
        setSummaryText(response.data.summary);
        console.log("Success");
        console.log(response);
        setLoading(false);
    })
    .catch(error => {
        console.log(error);
    });
    };

    return (
        <div className="container mx-auto p-4">
            <div className={`grid grid-cols-[80%_20%] gap-4`}>
                <div id="TextBoxes">
                    {showResult ? 
                        <></>
                    :
                        <div>
                            <div className={`grid grid-cols-[50%_50%] gap-4`}>
                                <GenericTextBox
                                    name="descriptionBox"
                                    boxText={description}
                                    setBoxText={setDescription}
                                    labelText="Description of input"
                                />
                                <GenericTextBox
                                    name="headingsBox"
                                    boxText={headings}
                                    setBoxText={setHeadings}
                                    labelText="Headings to use. One per line. With examples, use same headings as example"
                                />
                            </div>
                            <div>
                                <GenericTextBox
                                    name="exampleBox"
                                    boxText={example}
                                    setBoxText={setExample}
                                    labelText="Example Answer"
                                />
                            </div>
                        </div>
                    }

                    <ComponentSwap
                        component2={<PromptInputBox
                            inputText={inputText}
                            setInputText={setInputText}
                            headings={headings}
                            setHeadings={setHeadings}
                            description={description}
                            setDescription={setDescription}
                            loading={loading}
                            handleSubmit={handleSummarizeSubmit}
                        />}
                        component1={<PromptResultBox summaryText={summaryText} />}
                        duration={500}
                        switchTrigger={showResult}
                    />
    
                </div>

                <div id="OptionBoxes">
                    <ComponentSwap
                        component2={
                            <FormattingOptionsBox
                                bullets={bullets}
                                setBullets={setBullets}
                                pointLength={pointLength}
                                setPointLength={setPointLength}
                                attentionToDetail={attentionToDetail}
                                setAttentionToDetail={setAttentionToDetail}
                                persona={persona}
                                setPersona={setPersona}
                                preferredModelName={preferredModelName}
                                setPreferredModelName={setPreferredModelName}
                                splitMode={splitMode}
                                setSplitMode={setSplitMode}
                            />
                        }
                        component1={
                            <SummaryRefineBox 
                                refineOption={refineOption}
                                setRefineOption={setRefineOption}
                                loading={loading}
                                handleSubmit={handleRefineSubmit}
                            />
                        }
                        duration={500}
                        switchTrigger={showResult}
                    />
                </div>
            </div>
        </div>
    );
}
