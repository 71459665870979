import React from 'react';
import RadioButtonBar from './RadioButtonBar';
import DropdownList from './DropdownList';

export default function FormattingOptionsBox({bullets, setBullets, pointLength, setPointLength, attentionToDetail, 
        setAttentionToDetail, persona, setPersona, preferredModelName, setPreferredModelName, splitMode, setSplitMode}) {


    return (
        <div className='container mx-auto p-4 border rounded-md shadow-md'>
            <RadioButtonBar title="Use Bullets" key_values={{"Yes": true, "No": false}} state={bullets} stateSetter={setBullets} tooltipText="Create a bulleted summary or not"/>
            <RadioButtonBar title="Point Length" key_values={{"Short": "short", "Normal": "normal", "Long": "long"}} state={pointLength} stateSetter={setPointLength} tooltipText="How verbose the summarizer is with each point it adds to the summary"/>
            <RadioButtonBar title="Level of Detail" key_values={{"Low": "low", "Normal": "normal", "High": "high"}} state={attentionToDetail} stateSetter={setAttentionToDetail} tooltipText="Whether the summarizer includes small details, or focuses only on important details."/>
            <RadioButtonBar title="Long Text Handling" key_values={{"Summarize Sections": "map", "Running Summary": "refine"}} state={splitMode} stateSetter={setSplitMode} tooltipText="Very long texts will be split into chunks. These chunks can then be summarized individually or in a continuous fashion."/>
            <DropdownList title="Persona" key_values={{"Pharma Normal": "pharma_normal", "Pharma Attentive": "pharma_attentive"}} state={persona} stateSetter={setPersona} tooltipText="The style of assistant performing the summary"/>
        </div>
    );
}