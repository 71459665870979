import React from 'react';
import { useNavigate } from "react-router-dom";

export default function Login({user, setUser}) {
    const navigate = useNavigate();

    function handleLogin () {
        setUser({ id: '1', name: 'John', loginTime: Date.now() })
        console.log("Logged in")
        console.log(user)
        navigate("/homepage")
    }

    function handleLogout () {
        setUser(null);
        console.log("Logged out")
        console.log(user)
    }


    return (
        <div className="container mx-auto p-4">
            Hello and welcome to the login page.
            
            <div className="grid grid-cols-[33%_33%_34%] gap-4">

                <button onClick={handleLogout} className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600">Sign Out</button>

                <button onClick={handleLogin} className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600">Sign In</button>

            </div>


        </div>
    );  
}