import React from 'react';

export default function Landing() {
    return (
        <div className="container mx-auto p-4">
            Hello and welcome to the landing page.<br></br>
            If you aren't logged in yet, use the login button in the upper right.<br></br>
            If you are logged in, you can access the tools from the tools button in the upper right
        </div>
    );  
}