import React from 'react';

export default function RadioButton({title, text, value, state, stateSetter}) {

    const handleRadioChange  = (e) => {
        if (e.target.value === 'true') {
            stateSetter(true);
        }
        else if (e.target.value === 'false') {
            stateSetter(false);
        }
        else {
            stateSetter(e.target.value);
        }
        
    };

    return (
        <li class="w-full">
            <div class="flex items-center pl-3">
                <input 
                    type='radio' 
                    id={title + "_" + value} 
                    name={title} 
                    className="form-radio" 
                    value={value} 
                    checked={value === state} 
                    onChange={handleRadioChange} 
                />
                <label for={title + "_" + value} class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{text}</label>
            </div>
        </li>
    );
}