import React from 'react';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useLocalStorageState from 'use-local-storage-state'

import ProtectedRoute from './components/ProtectedRoute';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/privateLayout';
import Homepage from './pages/Homepage';
import NoPage from './pages/NoPage';
import TextSummarizer from './pages/TextSummarizer';
import Help from './pages/Help';
import Profile from './pages/Profile';
import TextImplications from './pages/TextImplications';
import FileSummarizer from './pages/FileSummarizer';
import Landing from './pages/Landing';
import Login from './pages/Login';

export default function App() {

    const [user, setUser] = useLocalStorageState('user', null)
    const expiryTime = 3600 // Expiry time of user login in seconds
    if (user) {
        if (Date.now() - user.loginTime > expiryTime*1000) {
            setUser(null)
        } 
    }
    let basename = ''
    if (process.env.REACT_APP_ENV === "DEVELOPMENT") { basename = '/ai_summer' }
    else                                             { basename = '/' }

    return (
        <div>
            <BrowserRouter basename={basename}>
                <Routes>
                    <Route element={<PublicLayout user={user} />}>
                        <Route index element={<Landing/>} />
                        <Route path="login" element={<Login user={user} setUser={setUser}/>} />
                        <Route path="*" element={<NoPage />} />
                    </Route>

                    <Route element={<ProtectedRoute user={user}/>}>
                        <Route element={<PrivateLayout user={user}/>}>
                            <Route path="homepage" element={<Homepage />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="help" element={<Help />} />
                            <Route path="text_summarizer" element={<TextSummarizer />} />
                            <Route path="text_implications" element={<TextImplications />} />
                            <Route path="file_summarizer" element={<FileSummarizer />} />
                        </Route>
                    </Route>

                </Routes>
            </BrowserRouter>
        </div>
    );  
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);