import React from 'react';
import { Outlet } from "react-router-dom";

import PublicNavbar from '../components/PublicNavbar';

export default function PublicLayout ({user}) {
    return (
        <div class="min-h-screen w-full relative">
            <PublicNavbar user={user}/>
            <div class="p-4 py-20">
                <main><Outlet /></main>
            </div>
        </div>
    )
}