import React from "react";

export default function PromptResultBox({summaryText}) {

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(summaryText);
  };

  return (
    <div className="container mx-auto p-4 border rounded-md shadow-md">
      <div className="mb-4">
        <div>
          <label htmlFor="inputText" className="block mb-2 font-bold">
            Summary Text
          </label>
          <textarea
            id="summaryText"
            name="summaryText"
            className={`transition-transform w-full p-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            rows="6"
            value={summaryText}
            readonly
          >
          </textarea>
        </div>

        <div className="grid grid-cols-[10%] gap-4 mb-4">
          <div>
            <button
              onClick={handleCopyToClipboard}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}